
.ant-row.content7-content{
  text-align: left;
}
.ant-tabs-top {
  text-align: center;
}
#Content13_0.lyexsu7e8rg-editor_css {
  font-size: 18px;
  background-image: url("");
  background-color: #3F2442;
  color:white !important;
}

/* token desc title  */
.lyk363rfz7u-editor_css{
  color:white
}

#Banner2_0 .banner-anim > .banner-anim-elem > .lyx5y6okua-editor_css {
  background-image: url(https://img.freepik.com/free-vector/abstract-background-with-digital-cyber-particles-design_1048-15577.jpg?t=st=1721662981~exp=1721666581~hmac=aabdaf3…&w=740);}

  #Banner2_0 .home-page > .banner2-text-wrapper > .lyx5wx11c4n-editor_css {
    font-size: 30px;
    clear: left;
  }
  

  #Feature0_0 .content0-block-item > div > .lyx5r94cmo-editor_css {
    top: 20px;
  }

  #Feature0_0 .content0-block-item > div > .lyx5rl85oz-editor_css {
    top: 20px;
  }
  #Feature0_0 .content0-block-item > div > .lyx5roei7sn-editor_css {
    top: 20px;
  }